/* =========== Navigation Bar ========= */
nav{
    padding: 40px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-menu{
    background-color: hsl(230, 35%, 7%);
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 140px 0px 30px 70px;
    align-items: flex-start;
    width: 80%;
    position: absolute;
    min-height: 100vh;
    top: 0px;
    left: -2000px;
    transition: 0.4s right ease-in-out;
    z-index: 2;
    min-width: 290px;
}
@supports (backdrop-filter: blur(81.5485px)){
    .nav-menu{
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(81.5485px);
    }
}
.nav-menu.active{
    left: 20vw;
}
.nav-item a{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2.7px;
    color: #FFFFFF;
    font-family: 'Barlow Condensed', sans-serif;
    text-transform: uppercase;
}
.nav-item a:hover,
.nav-item a.active {
    padding-bottom: 10px;
    border-bottom: 3px solid white;
}
.nav-num{
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 700;
    margin-right: 8px;
}

/* Line */
hr{
    width: 90vw;
    height: 1px;
    margin-left: 20px;
    border: 1px solid #979797;
    opacity: 0.25;
    display: none;
}

/* Hamburger */
.hamburger{
    background-image: url("../assets/shared/icon-hamburger.svg");
    width: 30px;
    height: 21px;
    cursor: pointer;
}
.hamburger.active{
    background-image: url("../assets/shared/icon-close.svg");
    width: 20px;
    height: 21px;
    position: relative;
    z-index: 2;
}
