/* ========= Homepage ========== */
.home-text{
    margin-left: 3vw;
    margin-top: 80px;
}
#heading{
    text-transform: uppercase;
    font-weight: 400;
    color: #D0D6F9;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2.7px;
}
h1{
    font-weight: 400;
    font-size: 80px;
    line-height: 100px;
    text-transform: uppercase;
    margin: 20px auto 10px;
}
.home-text p{
    font-weight: 400;
    color: #D0D6F9;
    width: 90%;
    font-size: 15px;
    line-height: 25px;
    font-family: 'Barlow', sans-serif;
    margin: 10px auto;
}
.explore-btn{
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 2px;
    color: #0B0D17;
    border-radius: 50%;
    padding: 80px 30px;
    border: 0;
    background-color: white;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin: 50px 0 30px;
    cursor: pointer;
}
.explore-btn::after{
    content: "";
    /* width: 330px; */
    /* height: 330px; */
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    display: block;
    top: -27px;
    left: -27px;
}
.explore-btn:hover::after{
    width: 260px;
    height: 250px;
}
