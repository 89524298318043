/* ======== Destination ========= */
.destination-container img {
  margin: 0 auto;
  width: 300px;
}
.destination {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Destination Names */
.destination-names {
  position: relative;
  left: 50%;
  transform: translate(-50%, 50%);
  margin: 30px 0 0;
  top: 50px;
}
.destination-names span {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2.7px;
  color: #d0d6f9;
  margin: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.destination-names span:hover,
.destination-names span.active {
  padding-bottom: 15px;
  border-bottom: 2px solid white;
  color: white;
}
.destination-names span:hover {
  opacity: 0.4;
}
/* Destination Text */
.destination h3 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 56px;
  line-height: 64px;
  margin: 100px auto 15px;
}
p {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #d0d6f9;
  width: 90%;
  margin: 0 auto;
}

/* Destination Information */
.destination-info {
  display: flex;
  gap: 20px;
  text-transform: uppercase;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0;
}
h4 {
  font-family: "Barlow Condensed";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2.3625px;
  color: #d0d6f9;
  margin: 10px 0;
}
.destination-info span {
  font-family: "Bellefair";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
}

/* ... (existing styles) ... */

/* "See Providers" Button */
.destination-info button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.destination-info button:hover {
  background-color: #0056b3; /* Change background color on hover */
}

/* === Destination === */
.destination-container img {
  margin: 0 auto;
  width: 300px;
}

.destination {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header select {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
  margin-top: 20px;
}
